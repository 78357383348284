<template>
  <div class="row mx-3" v-if="showNotification">
    <button class="burger " type="button" data-bs-toggle="modal"
            data-bs-target="#staticWarningsBackdrop">
      <span class="notification-badge bg-warning">{{ daysToExpire }} days</span>
      <font-awesome-icon icon="fa-solid fa-bell"/>
    </button>

    <div class="modal fade" id="staticWarningsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="staticWarningsBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="staticWarningsBackdropBackdropLabel">Notifications</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeWarningsBackdropModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul class="notification-list">
              <li class="notification-item">
                <div class="notification-details">
                  <h6 class="notification-title">Reminder: Subscription Expiry</h6>
                  <p class="notification-text">System will Expire in {{ daysToExpire }} days.</p>
                  <span class="notification-time">Don't let your business miss a beat, renew your subscription today</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" @click="makePayment" class="btn btn-primary">Make Payment</button>
            <button type="button" @click="viewSubscription" class="btn btn-primary">View Subscription & Accounts</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Notification',
  props : {

  },
  computed : {
    daysToExpire(){
      return this.$store.getters.getDaysToExpire;
    },

    showNotification(){
      return this.daysToExpire <= 12;
    },
  },
  methods: {
    makePayment(){
      this.$utils.showLoading();
      return this.$store.dispatch(`getPaymentRoute`)
          .then(()=>document.getElementById(`closeWarningsBackdropModal`).click())
          .finally(()=>this.$utils.hideLoading())
    },

    viewSubscription() {
      document.getElementById(`closeWarningsBackdropModal`).click();
      if (this.$route.name !== `subscription-accounts`){
        return this.$router.push({ name : `subscription-accounts` });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.notification-badge {
  position: fixed;
  margin-right: 5px;
  color: white;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  font-size: 12px;
  opacity: 0; /* Initially hidden */
  transform: scale(0); /* Initially small size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.burger .notification-badge {
  opacity: 1;
  transform: scale(1);
  animation: notification-pulse 1.5s infinite;
}

@keyframes notification-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
}

.notification-list {
  list-style: none;
  padding: 0;
}

.notification-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.notification-details {
  flex: 1;
}

.notification-title {
  margin: 0;
  font-size: 15px;
}

.notification-text {
  margin: 5px 0;
  color: #555;
}

.notification-time {
  font-size: 12px;
  color: #888;
}
</style>