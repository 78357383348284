import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "../views/LoginView.vue";
import Layout from '../components/layout/Layout.vue'
import UserLoginView from "../views/UserLoginView.vue";
import {Storage} from "@/Services/Storage";
import store from "../store/index";
import Invoices from '@/views/Billing/Invoices.vue';
import ViewInvoices from '@/views/Debtors/ViewInvoices.vue';
import pointOfPayment from '@/views/Debtors/pointOfPayment.vue';
import TicketDetail from '@/views/Tickets/TicketDetails.vue';
import InvoiceRecords from '@/components/point-of-sale/sales/Invoices/InvoiceRecords.vue';
import verifySupportLogin from "@/views/verifySupportLogin.vue";
import SupportOtp from "@/views/SupportOtp.vue";


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: LoginView
        },

        {
            path: '/userLogin',
            name: 'userLogin',
            component: UserLoginView
        },

        {
            path: '/verifySupportLogin',
            name: 'verifySupportLogin',
            component: verifySupportLogin
        },
        {
            path: '/SupportOtp',
            name: 'SupportOtp',
            component: SupportOtp
        },
        {
            path: '/',
            component: Layout,
            children: [

                //Stock
                {
                    path: '/stock',
                    name: 'stock',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock" */ '../views/Stock/StockView.vue'),
                },

                {
                    path: '/stock-create',
                    name: 'stock-create',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-create" */ '../views/Stock/CreateStockView.vue'),
                },

                {
                    path: '/stock-search',
                    name: 'stock-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-search" */ '../views/Stock/StockSearchView.vue'),
                },
                {
                    path: '/CaseHierachy',
                    name: 'case-hierarchy',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "case-hierarchy" */ '../views/Stock/Specials/CaseHierachy.vue'),
                },

                {
                    path: '/stock-edit',
                    name: 'stock-edit',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-edit" */ '../views/Stock/EditStockView.vue'),
                },

                {
                    path: '/stock-received-supplier',
                    name: 'stock-received-supplier',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-received-supplier" */ '../views/Stock/GRV/SelectSupplierView.vue'),
                },


                {
                    path: '/stock-receive-items',
                    name: 'stock-receive-items',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-received-items" */ '../views/Stock/GRV/SelectItemsReceived.vue'),
                },
                {
                    path: '/smart-invoice-grv',
                    name: 'smart-invoice-grv',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "smart-invoice-grv" */ '../views/Stock/GRV/SmartInvoiceGRVs'),
                },

                {
                    path: '/stock-take',
                    name: 'stock-take',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-take" */ '../views/Stock/StockTake/StockTakeView.vue'),
                },

                {
                    path: '/stock-recipes',
                    name: 'stock-recipes',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-recipes" */ '../views/Stock/links/recipes/StockRecipesView.vue'),
                },

                {
                    path: '/cooking-instructions',
                     name: 'cooking-instructions',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "cooking-instructions" */ '../views/Stock/links/CookingInstructionSetupView'),
                },

                {
                    path: '/view-purchase-orders',
                    name: 'view-purchase-orders',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "view-purchase-orders" */ '../views/Stock/PurchaseOrdersView'),
                },
                {
                    path: '/view-gate-entry',
                    name: 'view-gate-entry',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "view-gate-entry" */ '../views/Stock/GRV/GateEntryItemsView'),
                },

                {
                    path: '/stock-cases/',
                    name: 'stock-cases',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-cases" */ '../views/Stock/links/cases/StockCaseLinkView.vue'),
                },

                {
                    path: '/stock-cooking-instructions',
                    name: 'stock-cooking-instructions',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-cooking-instructions" */ '../views/Stock/StockView.vue'),
                },

                {
                    path: '/stock-categories-search',
                    name: 'stock-categories-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-categories-search" */ '../views/Stock/Categories/ViewCategories.vue'),
                },
                {
                    path: '/stock-adjustment',
                    name: 'stock-adjustment',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-adjustment" */ '../views/Stock/AdjustStockView.vue'),
                },

                {
                    path: '/stock-production',
                    name: "stock-production",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-production" */ '../views/Stock/ProductionView.vue')
                },
                {
                    path: '/PaymentMethods',
                    name: 'PaymentMethods',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "PaymentMethods" */ '../views/Finance/PaymentMethods'),
                },
                {
                    path: '/specials',
                    name: "specials",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "Special" */ '../views/Stock/Specials/Specials.vue')
                },
                {
                    path: '/discountSpecial/:id',
                    name: "discountSpecial",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "discountSpecial" */ '../views/Stock/Specials/discountSpecial.vue')
                },
                {
                    path: '/multiSpecial/:id',
                    name: "multiSpecials",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "multiSpecials" */ '../views/Stock/Specials/multiSpecial.vue')
                },
                {
                    path: '/comboSpecial/:id',
                    name: "comboSpecial",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "comboSpecial" */ '../views/Stock/Specials/comboSpecial.vue')
                },
                {
                    path: '/create-special',
                    name: "create-special",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-special" */ '../views/Stock/Specials/CreateSpecials.vue')
                },
                {
                    path: '/specials-edit',
                    name: "specials-edit",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "specials-edit" */ '../views/Stock/Specials/SpecialsEdit.vue')
                },
                {
                    path: '/mass-price-edit',
                    name: "mass-price-edit",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "mass-price-edit" */ '../views/Stock/MassPriceEditorView.vue')
                },
                {
                    path: '/MassStockUpdate',
                    name: "MassStockUpdate",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "mass-price-edit" */ '../views/Stock/MassStockUpdate.vue')
                },

                {
                    path: '/stock-conversion',
                    name: "stock-conversion",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-conversion" */ '../views/Stock/StockConversionView.vue')
                },
                //Debtors
                {
                    path: '/debtor-create',
                    name: 'debtor-create',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "debtor-create" */ '../views/Debtors/CreateDebtorView.vue'),
                },
                {
                    path: '/pre-loyalty',
                    name: 'pre-loyalty',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "pre-loyalty" */ '../views/Debtors/Loyalty/pre-loyalty.vue'),
                },

                {
                    path: '/main-debtors',
                    name: 'main-debtors',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "main-debtors" */ '../views/Debtors/MainDebtor/MainDebtorsView'),
                },
                {
                    path: '/Layby',
                    name: 'Layby',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "main-debtors" */ '../views/Debtors/Layby/Layby'),
                },
                {
                    path: '/lay-by-customer',
                    name: 'lay-by-customer',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "lay-by-customer" */ '../views/Debtors/Layby/LaybyCustomer'),
                },

                {
                    path: '/Loyalty',
                    name: 'Loyalty',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "main-debtors" */ '../views/Debtors/Loyalty/Loyalty'),
                },
                {
                    path: '/DebtorSafe',
                    name: 'DebtorSafe',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "DebtorSafe" */ '../views/Finance/DebtorSafe'),
                },

                {
                    path: '/edit-main-debtor',
                    name: 'edit-main-debtor',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "edit-main-debtor" */ '../views/Debtors/MainDebtor/EditMainDebtorView'),
                },

                {
                    path: '/debtor-search',
                    name: 'debtor-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "debtor-search" */ '../views/Debtors/DebtorSearchView.vue'),
                },

                {
                    path: '/debtor-update',
                    name: 'debtor-update',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "debtor-update" */ '../views/Debtors/ViewDebtorScreen.vue'),
                },
                {
                    path: '/invoice-delivery-note',
                    name: 'invoice-delivery-note',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "invoice-delivery-note" */ '../views/Debtors/DeliveryNotes/ViewDeliveryNote'),
                },

                {
                    path: '/quotes',
                    name: 'quotes',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "quotes" */ '../views/Debtors/QuotesView.vue'),
                },
                {
                    path: '/view-invoices',
                    name: 'ViewInvoices',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "ViewInvoices" */ '../views/Debtors/ViewInvoices'),
                },
                {
                    path: '/pointOfPayment',
                    name: 'pointOfPayment',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "pointOfPayment" */ '../views/Debtors/pointOfPayment'),
                },
                {
                    path: '/createExpenseCategory',
                    name: 'createExpenseCategory',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "quotes" */ '../views/Finance/createExpenseCategory.vue'),
                },
                {
                    path: '/viewExpensecategories',
                    name: 'viewExpensecategories',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "quotes" */ '../views/Finance/viewExpensecategories.vue'),
                },
                {
                    path: '/viewExpenseRecords',
                    name: 'viewExpenseRecords',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "quotes" */ '../views/Finance/viewExpenseRecords.vue'),
                },
                //Suppliers
                {
                    path: '/supplier-create',
                    name: 'supplier-create',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "supplier-create" */ '../views/Suppliers/CreateSupplierView.vue'),
                },
                {
                    path: '/Nonestock',
                    name: 'Nonestock',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-received-items" */ '../views/Stock/GRV/Nonestock.vue'),
                },

                {
                    path: '/supplier-search',
                    name: 'supplier-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "supplier-search" */ '../views/Suppliers/SelectSupplierView.vue'),
                },

                {
                    path: '/supplier-update',
                    name: 'supplier-update',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "supplier-update" */ '../views/Suppliers/ViewSupplierScreen.vue'),
                },

                //employee
                {
                    path: '/employee-create',
                    name: 'employee-create',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-create" */ '../views/Employees/EmpAddView.vue'),
                },

                {
                    path: '/employee-search',
                    name: 'employee-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-search" */ '../views/Employees/EmployeesView.vue'),
                },
                {
                    path: '/Float-screen',
                    name: 'Float-screen',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "float-screen" */ '../views/Employees/Float-screen.vue'),
                },

                {
                    path: '/employee-update',
                    name: 'employee-update',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-update" */ '../views/Employees/EmpEditView.vue'),
                },
                {
                    path: '/employee-security',
                    name: 'employee-security',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-security" */ '../views/Employees/Security/SecurityGroupsView.vue'),
                },

                {
                    path: '/employee-security-type',
                    name: 'employee-security-type',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-security-type" */ '../views/Employees/Security/SelectSecurityTypeView.vue'),
                },

                {
                    path: '/employee-security-update',
                    name: 'employee-security-update',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "employee-security-update" */ '../views/Employees/Security/UpdateSecurityView.vue'),
                },

                //Transfer
                {
                    path: '/stock-transfer',
                    name: "stock-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "stock-transfer" */ '../views/Stock/StockTransfer/SelectTransferTypeView.vue'),
                },
                {
                    path: '/create-missing-item',
                    name: "create-missing-item",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-missing-item" */ '../views/Stock/StockTransfer/CreateMissingItem'),
                },

                {
                    path: '/incoming-stock-transfer',
                    name: "incoming-stock-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "incoming-stock-transfer" */ '../views/Stock/StockTransfer/IncomingTransfersView.vue'),
                },

                {
                    path: '/rejected-stock-transfer',
                    name: "rejected-stock-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "rejected-stock-transfer" */ '../views/Stock/StockTransfer/RejectedTransfersView.vue'),
                },

                {
                    path: '/saved-stock-transfer',
                    name: "saved-stock-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "saved-stock-transfer" */ '../views/Stock/StockTransfer/SavedTransfersView.vue'),
                },

                {
                    path: '/not-received-transfer',
                    name: "not-received-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "not-received-transfer" */ '../views/Stock/StockTransfer/NotReceivedTransfersView.vue'),
                },

                {
                    path: '/view-stock-transfer',
                    name: "view-stock-transfer",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "view-stock-transfer" */ '../views/Stock/StockTransfer/ViewTransferView.vue'),
                },

                {
                    path: '/transfer-items',
                    name: "transfer-items",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "transfer-items" */ '../views/Stock/StockTransfer/ViewItemsToSendView.vue'),
                },
                {
                    path: '/StockArchive',
                    name: "StockArchive",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "transfer-items" */ '../views/Stock/StockArchive.vue'),
                },
                {
                    path: '/transfer-items-search',
                    name: "transfer-items-search",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "transfer-items-search" */ '../views/Stock/StockTransfer/SearchSendingItemsView.vue'),
                },

                {
                    path: '/select-receiving-store',
                    name: "select-receiving-store",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-receiving-store" */ '../views/Stock/StockTransfer/SelectReceivingStoreView.vue'),
                },
                {
                    path: '/AssetCategories',
                    name: "AssetCategories",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-receiving-store" */ '../views/Finance/AssetCategories.vue'),
                },
                {
                    path: '/AssetRecords',
                    name: "AssetRecords",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-receiving-store" */ '../views/Finance/AssetRecords.vue'),
                },

                {
                    path: '/select-cooking-instructions',
                    name: "select-cooking-instructions",
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-cooking-instructions" */ '../views/Stock/StockTransfer/CookingInstruction.vue'),
                },
                 {
                    path: '/CreateExpenseCategory',
                    name: 'CreateExpenseCategory',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "CreateExpenseCategory" */ '../components/Finance/CreateExpenseCategory.vue'),
                },
                //Finance
                {
                    path: '/KazangOptions',
                    name: 'KazangOptions',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "finance-select" */ '../views/Finance/KazangOptions.vue'),
                },
                {
                    path: '/ResetAccount',
                    name: 'ResetAccount',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "finance-select" */ '../views/Finance/ResetAccount.vue'),
                },
                {
                    path: '/KazangRecords',
                    name: 'KazangRecords',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "finance-select" */ '../views/Finance/KazangRecords.vue'),
                },
                {
                    path: '/KazangSignup',
                    name: 'KazangSignup',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "finance-select" */ '../views/Finance/KazangSignup.vue'),
                },
                {
                    path: '/smart-invoicing-setup',
                    name: 'smart-invoicing-setup',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "smart-invoicing-setup" */ '../views/Finance/Smart-invoicing-setup.vue'),
                }, {
                    path: '/finance-select',
                    name: 'finance-select',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "finance-select" */ '../views/Finance/FinanceSelectView.vue'),
                },
                {
                    path: '/currency',
                    name: 'currency',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "currency" */ '../views/Finance/CurrencySetupView'),
                },

                //Safes
                {
                    path: '/safe-search',
                    name: 'safe-search',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "safe-search" */ '../views/Finance/Safes/SafeSearchView.vue'),
                },

                {
                    path: '/view-safe',
                    name: 'view-safe',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "view-safe" */ '../views/Finance/Safes/SafeView.vue'),
                },
                {
                    path: '/subscription-accounts',
                    name: 'subscription-accounts',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "subscription-accounts" */ '../views/Billing/SubscriptionsAndAccountsView'),
                },

                {
                    path: '/customer-quotes',
                    name: 'customer-quotes',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "customer-quotes" */ '../views/Billing/CustomerQuotes'),
                },
                {
                    path: '/Invoices',
                    name: 'Invoices',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "Invoices" */ '../views/Billing/Invoices'),
                },

                {
                    path: '/Lyt-SoftApps',
                    name: 'Lyt-SoftApps',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "Lyt-SoftApps" */ '../views/Lyt-softApps/Lyt-SoftApps.vue'),
                },
                {
                    path: '/email-setup/:mainCustomerID',
                    name: 'email-setup',
                    component: () => import(/* webpackChunkName: "email-setup" */ '../views/Settings/EmailSetupView')
                },
                {
                    path: '/SystemSettings',
                    name: 'SystemSettings',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemSettings" */ '../views/Settings/SystemSettings.vue'),
                },
                {
                    path: '/SystemAdjustments',
                    name: 'SystemAdjustments',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/Settings/SystemAdjustments.vue'),
                },
                {
                    path: '/Labelsetup',
                    name: 'Labelsetup',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/Settings/Labelsetup.vue'),
                },
                {
                    path: '/Ticket',
                    name: 'Create-Ticket',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-ticket" */ '../views/Tickets/Ticket.vue'),
                },
                {
                    path: '/ViewOpenTicket',
                    name: 'ViewOpenTicket',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/Tickets/ViewOpenTicket.vue'),
                },
                {
                    path: '/smart-invoice-imports',
                    name: 'smart-invoice-imports',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "smart-invoice-imports" */ '../views/Stock/GRV/IncomingImportsView'),
                },
                {
                    path: '/create-manual-ledger-entry',
                    name: 'create-manual-ledger-entry',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-manual-ledger-entry" */ '../views/Finance/ManualLedgerEntryView'),
                },

                {
                    path: '/RestAccount',
                    name: 'RestAccount',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "RestAccount" */ '../views/Finance/ResetAccount.vue'),
                },
                {
                    path: '/ZedMobileSetup',
                    name: 'ZedMobileSetup',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "ZedMobileSetup" */ '../views/Finance/ZedMobileSetup.vue'),
                },
                {
                    path: '/ViewClosedTicket',
                    name: 'ViewClosedTicket',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "ViewClosedTicket" */ '../views/Tickets/ViewClosedTicket.vue'),
                },
                {
                    path: '/ticket/:id',
                    name: 'TicketDetail',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "TicketDetail" */ '../views/Tickets/TicketDetails.vue'),
                    // component: TicketDetail,
                },
                {
                    path: '/InvoiceRecords',
                    name: 'InvoiceRecords',
                    component: () => import(/* webpackPrefetch: true  , webpackChunkName: "InvoiceRecords" */ '../components/point-of-sale/sales/Invoices/InvoiceRecords.vue'),
                },
            ]
        },

    ]
})
router.beforeEach(async (to, from, next) => {
    let auth = to.query.auth;
    if (auth){
        try {
            localStorage.clear();
            $utils.showLoading()
            let loginData = window.atob(auth);
            loginData = JSON.parse(loginData);

            let deviceID = loginData.device;
            await Storage.setDeviceID(deviceID);

            let mobile = loginData.mobileUser;
            let isAndroid = !!loginData.isAndroid;
            let defaultStore = loginData.store;
            let user = loginData.user;
            let page = loginData.page;
            if (!page) page = {name: 'stock'};
            await store.dispatch(`mobileUserLogin`,mobile);
            await store.dispatch(`loginToStore`,defaultStore);
            await store.dispatch(`getAllUsers`)
            await store.dispatch(`validateUser`,user);
            await Storage.setAndroid(isAndroid);
            next(page)
        }finally {
            $utils.hideLoading()
        }

    }else if (to.name !== 'login'){
        let mobileUser = await Storage.getMobileUser();
        let store = await Storage.getCurrentStore();
        let storeUser = await Storage.getCurrentUser();
        if (!mobileUser || !store) {
            return Promise.all([
                responses.showWarning(`Please Login!`),
                next({name: 'login'})
            ]);
        } else if (!storeUser && to.name !== 'userLogin') {
            return Promise.all([
                responses.showWarning(`User logged out Please Login!`),
                next({name: 'userLogin'})
            ]);
        } else next();
    }else next();
})


export default router
