import {Storage as Store} from '@capacitor/storage';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const DEVICE_ID = 'deviceID'

export const AllSecurities = 'allSecurities'
export const MOBILE_USER = 'mobileUser'
export const BASE_CURRENCY = 'baseCurrency'
export const STORE_CURRENCIES = 'storeCurrencies'
export const PAYMENT_METHODS = 'paymentMethods'
export const ALL_STORES = 'allStores'
export const CURRENT_STORE = 'currentStore'

//Users
export const ALL_USERS = 'allUsers'
export const CURRENT_USER = 'currentUser'


//Stock
export const STOCK = 'stock'
export const CATEGORIES = 'categories'

//Transfers
export const SENDING_ITEM = 'sendingItem'

//VAT & Tax
export const TAX_LABELS = 'taxLabels'

const encryptAdmin = (val) => {
    return window.btoa(val);
}
const decryptAdmin = (val) => {
    if (!val) return undefined;
    if (val.toString().charAt(0)===`"`){
        val = val.toString().slice(1, -1);
    }
    return JSON.parse(window.atob(val))
}

const setValue = (key, value) => Store.set({key: key, value: JSON.stringify(value)})

const getFingerPrintID = () => {
    const fpPromise = FingerprintJS.load();
    return fpPromise
        .then(fp => fp.get())
        .then(result => {
            return result.visitorId;
        })
}

const getValue = async (key) => {
    const value = await Store.get({
        key: key
    })
    return JSON.parse(value.value);
}

export const Storage = {
    saveItem(key, value) {
        localStorage.setItem(key, value);
    },
    getItem(key) {
        return localStorage.getItem(key);
    },
    removeItem(key) {
        localStorage.removeItem(key);
    },
    clearAll() {
        localStorage.clear();
    },
    setPaymentMethods: async (payload) => setValue(PAYMENT_METHODS, payload),
    getPaymentMethods: async () => getValue(PAYMENT_METHODS),

    setAndroid: async (payload) => {
        let value = JSON.stringify(payload);
        return await setValue(`isAndroid`, value);
    },
    getSessionID: async () => {
        const value = await Store.get({ key: SESSION_ID })
        return JSON.parse(value.value);
    },
    setSessionID: async (payload) => {
        await Store.set({
            key: SESSION_ID,
            value: JSON.stringify(payload)
        })
    },
    getAndroid: async () => {
        return await getValue(`isAndroid`);
    },

    setMobileUser: async (payload) => {
        let value = encryptAdmin(JSON.stringify(payload));
        return await setValue(MOBILE_USER, value);
    },
    getMobileUser: async () => {
        const value = await Store.get({ key: MOBILE_USER })
        return decryptAdmin(value.value)
    },

    setAllStores: async (payload) => setValue(ALL_STORES, payload),
    getAllStores: async () => {
        return await getValue(ALL_STORES)
    },
    setUsers: async (payload) => {
        await Store.set({
            key: AllUsers, value: JSON.stringify(payload)
        })
    },
    setCurrentStore: async (payload) => setValue(CURRENT_STORE, payload),
    getCurrentStore: async () => {
        return await getValue(CURRENT_STORE);
    },
    getCurrentStoreDB: async () => {
        let store = await getValue(CURRENT_STORE);
        if (!store) return null;
        return store.StoreDB;
    },

    getCurrentStoreID: async () => {
        let store = await getValue(CURRENT_STORE);
        if (!store) return null;
        return store.CustomerID;
    },

    //Stock
    setStock: async (payload) => setValue(STOCK,payload),
    getAllStock: async () => getValue(STOCK),

    //Stock Transfers
    setSendingItem: async (payload) => setValue(SENDING_ITEM,payload),
    getSendingItem: async () => getValue(SENDING_ITEM),

    setCategories: async (payload) => setValue(CATEGORIES, payload),
    getCategories: async () => getValue(CATEGORIES),

    setBaseCurrency: async (payload) => setValue(BASE_CURRENCY, payload),
    getBaseCurrency: async () => getValue(BASE_CURRENCY),

    setStoreCurrencies: async (payload) => setValue(STORE_CURRENCIES, payload),
    getStoreCurrencies: async () => getValue(STORE_CURRENCIES),

    //Users
    setCurrentUser: async (payload) => setValue(CURRENT_USER, payload),
    getCurrentUser: async () => getValue(CURRENT_USER),

    setAllUsers: async (payload) => setValue(ALL_USERS, payload),
    getAllUsers: async () => getValue(ALL_USERS),

    //VAT & Tax
    setTaxLabels : async (payload) =>setValue(TAX_LABELS , payload),
    getAllTaxLabels : async () => getValue(TAX_LABELS),


    //Device ID
    setDeviceID : async (payload) =>setValue(DEVICE_ID , payload),
    getDeviceID : async () => {
        let deviceID = await getValue(DEVICE_ID);
        if (!deviceID){
            try {
                deviceID = await getFingerPrintID();
            } catch (e) {
                deviceID = generateUUID();
            }
            await setValue(DEVICE_ID, deviceID);
        }
        return deviceID;
    },

    setAllSecurities: async (users) => {
        await Store.set({
            key: AllSecurities, value: JSON.stringify(users)
        })
    },
    getAllSecurities: async () => {
        const value = await Store.get({
            key: AllSecurities
        });
        return JSON.parse(value.value);
    },
}
