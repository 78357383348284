import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(BootstrapVue);
Vue.use(VueSignaturePad);
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/icons.js'
import './Services/GlobalVariables'
import DrawerLayout from 'vue-drawer-layout'
import Loading from 'vue-loading-overlay';
import {VueEditor} from "vue2-editor";
import VueQriously from 'vue-qriously'

import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBarcodeScanner from 'vue-barcode-scanner'
import './registerServiceWorker'
let options = {
  sensitivity: 100, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}

Vue.use(VueQriously);
Vue.use(VueBarcodeScanner, options);
Vue.component('vue-editor',VueEditor)

Vue.component('v-select', vSelect)
Vue.component('loading', Loading);
Vue.use(DrawerLayout);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
