import {Storage} from "@/Services/Storage";
import TimeManager from "@/Services/TimeManager";
import axios from "axios";


export default {
    name: 'Reports',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        openVATReports: async (context) => {
            let obj = {
                name: 'VATScreen',
                params: { StoreDB: await Storage.getCurrentStoreDB() }
            };
            return context.dispatch(`openFranchisePlatform`, obj)
        },
        openGrvReceived: async (context) => {
            let obj = {
                name: 'GRVReports',
                params: { StoreDB: await Storage.getCurrentStoreDB() }
            };
            return context.dispatch(`openFranchisePlatform`, obj)
        },
        openBelowMin: async (context) => {
            let obj = {
                name: 'ReOrder',
                params: { StoreDB: await Storage.getCurrentStoreDB() }
            };
            return context.dispatch(`openFranchisePlatform`, obj)
        },
        last10DaysTurnOver: async() => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = TimeManager.getForNoOfDays(10);
            return axios.get(franchiseApi+`sales/getDailyTurnover/`+storeDB,{ params : range })
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        async exportSalesDump() {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi+`excel/exportSalesDump/${storeDB}`)
        }
    }
}
