import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'deliveryNote',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        convertQuoteToDeliveryNote: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();

            let obj = {
                saleNumber,
                user: user.Username
            }
            return axios.post(backOfficeTomcatApi + `deliveryNotes/${storeDB}/convertFromQuote`, obj)
                .catch(err => errorHandler.tomcatError(err))
        },
        getDeliveryNotesByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `deliveryNotes/${storeDB}/getByDebtor?debtorID=${id}`)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        getDeliveryNoteBySaleNumber: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `deliveryNotes/${storeDB}/getBySaleNumber?saleNumber=${saleNumber}`)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        checkStockAvailability: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return context.dispatch(`getDeliveryNoteBySaleNumber`, saleNumber).then(response => {
                let saleInfo = response;
                saleInfo.PC = "Online";
                return axios.post(onlineSalesTomcatApi + `invoice/${storeDB}/checkStockAvailability`,saleInfo)
                    .then(({data}) => data).catch((err) => { throw new Error(err); })
            }).catch((err) => errorHandler.tomcatError(err))
        },
        convertDeliveryToInvoice: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();

            let obj = {
                ...payload,
                user: user.Username
            }
            return axios.post(backOfficeTomcatApi + `deliveryNotes/${storeDB}/convertToInvoice`, obj)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        mergeDeliveryNotes: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(backOfficeTomcatApi + `deliveryNotes/${storeDB}/mergeDeliveryNotes`, payload)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        printDeliveryNote: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return window.open(printApi + `debtor/${storeDB}/printDeliveryNote/${saleNumber}`, `_blank`);
        }
    }
}
