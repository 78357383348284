<template>
  <a class="floating-container" @click="$emit(`click`)">
    <div class="floating-button">
      <font-awesome-icon :icon="icon"/>
    </div>
  </a>
</template>
<script>
export default {
  name: 'FloatingAddButton',
  props : {
    icon : {
      type : String,
      default : "add",
      required : false,
    }
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");

@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

.floating-container {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  margin: 35px 25px;
}

.floating-container:hover {
  height: 90px;
}

.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-container .floating-button {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 50px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
</style>