import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'cooking-instructions',
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        searchCookingInstructions: async (context , input) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `cookingInstruction/${storeDB}/searchCookingLinks?linkName=${input}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getCookingLinkByItem: async (context , plu )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `cookingInstruction/${storeDB}/getLinkNameByItem?plu=${plu}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        getCookingGroupsByLink: async (context , linkID )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `cookingInstruction/${storeDB}/getGroupsByLink/${linkID}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        createCookingLink: async (context , payload )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `cookingInstruction/${storeDB}`,payload)
                .catch(err => errorHandler.tomcatError(err));
        },

        createCookingGroup: async (context , payload )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `cookingInstruction/${storeDB}/createGroup`,payload)
                .catch(err => errorHandler.tomcatError(err));
        },
        getGroupMessages: async (context , groupID )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `cookingInstruction/${storeDB}/group_messages/${groupID}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        deleteGroup: async (context , groupID )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `cookingInstruction/${storeDB}/deleteGroup/${groupID}`)
                .catch(err => errorHandler.tomcatError(err));
        },

        deleteCookingMessage: async (context , messageID )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `cookingInstruction/${storeDB}/deleteMessage/${messageID}`)
                .catch(err => errorHandler.tomcatError(err));
        },

        createMessageInCookingGroup: async (context , payload )=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `cookingInstruction/${storeDB}/group_messages/${payload.groupID}`,payload)
                .catch(err => errorHandler.tomcatError(err));
        },
    }
}
