import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'chartOfAccounts',
    state: {
        chartTypes: ["Asset","Equity","Expense","Liability","Revenue"]
    },
    getters: {
        getChartTypes:(state)=>state.chartTypes,
    },
    actions: {
        getChartsByType:async (context , obj)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`chartOfAccounts/${storeDB}/getAccounts/${obj.type}?Currency=${obj.currency}`)
                .then(({data})=> {
                    if (data === null)data = [];
                    const uniqueData = [...new Set(data)];
                    return uniqueData.sort();
                }).catch(err=>errorHandler.tomcatError(err))
        },
        processManualLedgerEntry:async (context , obj)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            obj.user = user.Username;
            return axios.post(backOfficeTomcatApi+`chartOfAccounts/${storeDB}/manualEntry`,obj)
                .catch(err=>errorHandler.tomcatError(err))
        }
    }
}
