import {Storage} from "@/Services/Storage";
import axios from "axios";
import TimeManager from "@/Services/TimeManager";
export default {
    name: 'billing',
    state: {
        daysToExpire : 30,
    },
    getters: {
        getDaysToExpire: (state) => state.daysToExpire,
    },
    mutations: {
        setDaysToExpire : (state , data) => state.daysToExpire = data,
    },
    actions: {
        getExpiryDateByCustomerID : async (context) => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getExpiryDate?customer=`+customerID)
              .then(({data})=> {
                  data = data.replaceAll(` 00:00:00`,``)
                  let days = TimeManager.getDateDiff(TimeManager.getNow() , data)
                  context.commit(`setDaysToExpire`,days);
                  if (days <= 0){
                      return Swal.fire({
                          title: `System Expired ! `,
                          icon: `error`,
                          html : `<p>Please make payment to continue</p>`,
                          showCancelButton : true,
                          confirmButtonText : `Continue`,
                          showLoaderOnConfirm : true,
                          preConfirm: async () => {
                              return context.dispatch(`getPaymentRoute`);
                          }
                      }).then(()=>{
                          return Swal.fire({
                              title: `System Expired ! `,
                              icon: `info`,
                              html: `<p>You will be logged out</p> <br> <span>You can login again after making payment</span>`,
                          }).then(() => {
                              return context.dispatch(`clearStoreState`)
                                  .then(async () => {
                                      let deviceID = await Storage.getDeviceID();
                                      localStorage.clear();
                                      await Storage.setDeviceID(deviceID)
                                      try {
                                          await this.$router.push({name: 'login'})
                                      } catch (e) {
                                      }
                                      return window.location.reload(true);
                                  });
                          });
                      });
                  }
              }).catch(err=>errorHandler.tomcatError(err))
        },

        getCustomerBalance : async (context) => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getBalance?customer=`+customerID)
              .then(({data})=> data).catch(err=>errorHandler.tomcatError(err))
        },

        hasOverdueCount : async (context) => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`hasOverdueCount?customer=`+customerID)
              .then(({data})=> data).catch(err=>errorHandler.tomcatError(err))
        },

        getDueInvoices : async () => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getDueInvoice?customer=`+customerID)
              .then(({data})=> data).catch(err=>errorHandler.tomcatError(err))
        },

        getActiveStores : async () => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getActiveStores?customer=`+customerID)
              .then(({data})=> data).catch(err=>errorHandler.tomcatError(err))
        },

        getCustomerQuotes : async (context) => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getQuotes?customer=`+customerID)
              .then(({data})=> data).catch(err=>errorHandler.tomcatError(err))
        },

        getPaymentRoute : async () => {
          let customerID = await Storage.getCurrentStoreID();
          if (!customerID) return ;
          return axios.get(customerPanelApi+`getPaymentRoute?id=`+customerID)
              .then(({data})=> openUrl(data))
              .catch(err=> {
                  if (err.response.status === 406) return responses.showInfo(err.response.data);
                  else return errorHandler.tomcatError(err)
              })
        },

        getStatementForPeriod : async (context , period) => {
            let customerID = await Storage.getCurrentStoreID();
            let payload = {
                customer : customerID,
                from : TimeManager.getNowMinusDays(period),
                to : TimeManager.getNow(),
            };
            return openUrl(customerPanelApi+`getStatement?`+stringify(payload));
        }
    }
}
