<template>
  <div class="page">
    <BackNavigationBar :back="back" :title="title" :hide-button="hideBackButton"/>
    <slot />
    <div class="last-component"></div>
  </div>
</template>

<script>
import BackNavigationBar from "../navigation/BackNavigationBar.vue";
export default {
name: "PageWithBackBtn",
  components: {BackNavigationBar},
  props : {
    back : {
      type : Function,
      default : function () { this.$router.back() }
    },
    hideBackButton : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      required : true
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  background: white !important;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.last-component{
  margin-bottom: 75px;
}
</style>