<template>
  <full-page title="Proof of Payment">
    <div class="card m-3">
      <div class="card-header text-primary">
        <b>All Payments</b>
      </div>
      <div class="card-body p-0">
        <b-tabs v-model="activeTab">
          <b-tab title="Pending" @click="setActiveTab(0)">
            <vue-good-table :search-options="{ enabled: true }" :columns="pendingColumns" :rows="payments"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'customer'">
                  <span style="font-size: 13px">{{ props.row.customerName }}</span>
                </div>
                <div v-else-if="props.column.field === 'type'">
                  <span class="d-flex flex-column">{{ props.row.type }}</span>
                </div>
                <div v-else-if="props.column.field === 'status'">
                  <span>{{ props.row.status }}</span>
                </div>
                <div v-else-if="props.column.field === 'documentNo'">
                  <span>{{ props.row.documentNo }}</span>
                </div>
                <div v-else-if="props.column.field === 'amount'">
                  <span>{{ props.row.amount }}</span>
                </div>
                <div v-else-if="props.column.field === 'action'">
                  <a @click="handleFileAction(props.row)" class="btn btn-primary">
                    <font-awesome-icon icon="file"/>
                  </a>

                  <a @click="handleCrossAction(props.row)" class="mx-2 btn btn-danger">
                    <font-awesome-icon icon="times"/>
                  </a>
                  <a @click="handleTickAction(props.row)" class="mx-2 btn btn-success">
                    <font-awesome-icon icon="check"/>
                  </a>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab title="Processed" @click="setActiveTab(1)">
            <vue-good-table :search-options="{ enabled: true }" :columns="processedColumns" :rows="payments"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'customer'">
                  <span style="font-size: 13px">{{ props.row.customerName }}</span>
                </div>
                <div v-else-if="props.column.field === 'type'">
                  <span class="d-flex flex-column">{{ props.row.type }}</span>
                </div>
                <div v-else-if="props.column.field === 'status'">
                  <span>{{ props.row.status }}</span>
                </div>
                <div v-else-if="props.column.field === 'documentNo'">
                  <span>{{ props.row.documentNo }}</span>
                </div>
                <div v-else-if="props.column.field === 'amount'">
                  <span>{{ props.row.amount }}</span>
                </div>
                <div v-else-if="props.column.field === 'date'">
                  <span>{{ props.row.date }}</span>
                </div>
                <div v-else-if="props.column.field === 'action'">
                  <a @click="handleFileAction(props.row)" class="btn btn-primary">
                    <font-awesome-icon icon="file"/>
                  </a>
                  <span
                      :class="{'accepted': props.row.action === 'Accepted', 'declined': props.row.action === 'Declined'}">
                    {{
                      props.row.action === 'Accepted' ? 'Accepted' : (props.row.action === 'Declined' ? 'Declined' : '')
                    }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFile, faTimes, faCheck} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {Storage} from "@/Services/Storage";

library.add(faFile, faTimes, faCheck);

export default {
  name: "Payments",
  components: {FloatingAddButton, FullPage},
  beforeMount() {
    this.getAllPaymentOptions()
  },
  data() {
    return {
      activeTab: 0,
      pendingColumns: [
        {label: "Document #", field: "documentNumber"},
        {label: "Customer", field: "customerName"},
        {label: "Type", field: "type"},
        {label: "Status", field: "status"},
        {label: "Amount", field: "amount"},
        {label: "date", field: "date"},
        {label: "Action", field: "action"},
      ],
      processedColumns: [
        {label: "Document #", field: "documentNumber"},
        {label: "Customer", field: "customerName"},
        {label: "Type", field: "type"},
        {label: "Status", field: "status"},
        {label: "Amount", field: "amount"},
        {label: "date", field: "date"},
        {label: "Action", field: "action"},
      ],
      pageOptions: {
        enabled: true,
        perPage: 10
      },
      payments: [],
      paymentMethod: [],
      form: {
        customer: null,
        type: null,
        allStores: true,
        weight: null,
        size: null
      }
    };
  },
  computed: {
    filteredPayments() {
      return this.payments;
    },

    paymentTypes() {
      return [
        'MTN MONEY',
        'Airtel Money',
        'Kazany'
      ];
    },
  },
  methods: {
    async setActiveTab(tab) {
      this.activeTab = tab;
      this.$utils.showLoading()
      return this.searchPayments().finally(()=>this.$utils.hideLoading());
    },
    async searchPayments() {
      try {
        const DB = await Storage.getCurrentStoreDB();
        const processedStatus = this.activeTab !== 0;
        const response = await axios.get(`${backOfficeTomcatApi}popValidation/${DB}/getByStatus?processed=${processedStatus}`);
        this.payments = response.data;
      } catch (error) {
        return errorHandler.tomcatError(error)
      }
    },
    getAllPaymentOptions() {
      return this.$store.dispatch(`getAllSafes`)
          .then(res => this.paymentMethod = res);
    },
    async handleTickAction(row) {
      const {value: paymentMethod} = await Swal.fire({
        icon: `question`,
        title: 'Select Payment Method',
        input: 'select',
        inputOptions: this.paymentMethod,
        inputPlaceholder: 'Select a payment method',
        showCancelButton: true,
        customClass: {
          popup: 'swal2-popup-lg'
        }
      });

      if (paymentMethod) {
        Swal.fire({
          icon : "warning",
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              const DB = await Storage.getCurrentStoreDB();
              const user = await Storage.getCurrentUser();
              await axios.post(`${backOfficeTomcatApi}popValidation/${DB}/approvePOP`, {
                id: row.id,
                paymentMethod: paymentMethod,
                user: user.Username
              }).then(()=>this.searchPayments());
            } catch (error) {
              return errorHandler.tomcatError(error)
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            return responses.showInfo(`POP approved successfully`)
          }
        });
      }
    },
    async handleFileAction(row) {
      const DB = await Storage.getCurrentStoreDB();
      window.open(backOfficeTomcatApi + `popValidation/${DB}/getFileByPOP?id=` + row.id);
    },
    async handleCrossAction(row) {
      const {value: reason} = await Swal.fire({
        icon : `question`,
        title: 'Give Reason',
        input: 'textarea',
        inputPlaceholder: 'Enter your reason here...',
        showCancelButton: true,
        confirmButtonText: 'Send',
        customClass: {
          popup: 'swal2-popup-lg'
        }
      });

      if (reason) {
        Swal.fire({
          icon : `question`,
          title: 'Are you sure?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          customClass: {
            popup: 'swal2-popup-lg'
          },
          preConfirm:async ()=> {
            try {
              const user = await Storage.getCurrentUser();
              const DB = await Storage.getCurrentStoreDB();
              await axios.post(`${backOfficeTomcatApi}popValidation/${DB}/declinePOP`, {
                id: row.id,
                reason: reason,
                user: user.Username
              }).then(()=>this.searchPayments());
            } catch (error) {
              return errorHandler.tomcatError(error)
            }
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

          }
        });
      }
    },
  },
  mounted() {
    this.setActiveTab(0);
  }
};
</script>

<style scoped>
.vue-file-icon {
  cursor: pointer;
}

.accepted {
  color: green;
  font-weight: bold;
}

.declined {
  color: red;
  font-weight: bold;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>
