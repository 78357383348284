import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'payment-methods',
    state: {
        kazangDetails : null,
        paymentMethods : [],
    },
    getters: {
        getPaymentMethods : (state) => state.paymentMethods,
        getKazangDetails:(state)=> state.kazangDetails
    },
    mutations: {
        setPaymentMethods : async (state , data) => {
            if (data){
                await Storage.setPaymentMethods(data);
            }else {
                data = await Storage.getPaymentMethods();
            }
            state.paymentMethods = data
        },
        setKazangDetails:(state , data)=> state.kazangDetails = data
    },
    actions: {
        getAllPaymentMethods : async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(franchiseTomcatApi + `payment_options/${storeDB}/getAll`)
                .then(({data}) => {
                    context.commit(`setPaymentMethods`,data)
                    return data;
                }).catch((err) => errorHandler.tomcatError(err))
        },
        createPaymentMethod : async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(franchiseTomcatApi + `payment_options/${storeDB}`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },

        editPaymentMethod : async (context, data) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi + `payment_options/${storeDB}/${data.id}`, data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        deactivatePaymentMethod : async (context, data) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi + `payment_options/${storeDB}/deactivate`, data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getKazangDetails : async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(subscriptionTomcatApi + `customerUpdate/getKazangDetails/${storeDB}`)
                .then(({data})=>context.commit(`setKazangDetails`,data))
                // .catch((err) => errorHandler.tomcatError(err))
        },


    }
}
