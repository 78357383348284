<template>
  <page-with-back-btn :title="`Tickets #${id}`">
    <div class="d-flex">
      <div class="m-3 flex-grow-1">
        <h4>{{ ticket.title }}</h4>
        <hr/>
        <div>
          <p>Status : <span :class="`badge `+(ticket.status===`CLOSED`?`bg-success`:`bg-warning`)">{{ ticket.status }}</span></p>
          <p class="mb-0">Store : {{ ticket.store ? ticket.store : `No Store Specified` }}</p>
          <line-separator title="Ticket Info"/>
          <p class="mb-0">Type : {{ ticket.category }}</p>
          <p class="mb-0">Sub-Type : {{ ticket.subCategory }}</p>
          <p class="mb-0">Assigned To : {{ ticket.createdBy }}</p>
          <p class="mb-0" v-if="ticket.createdDate">Reported Date : {{ $utils.displayDate(ticket.createdDate , true) }}</p>
        </div>
        <div v-if="ticket.description">
          <line-separator title="Ticket Description"/>
          <div v-html="ticket.description"></div>
        </div>
        <hr/>
      </div>
    </div>

    <!-- Bottom Section -->
    <div class="d-flex flex-wrap bottom-section">
      <!-- Notes Section -->
      <div class="flex-grow-1 m-3">
        <h5>Notes</h5>
        <div v-if="notes && notes.length">
          <ul>
            <li v-for="(note, index) in filteredNotes" :key="index">{{ stripHtmlTags(note.Note) }}</li>
          </ul>
        </div>
        <div v-else>
          <p>No notes available.</p>
        </div>
      </div>

      <!-- Attachments Section -->
      <div class="flex-grow-1 m-3">
        <h5>Attachments</h5>
        <div v-if="attachments && attachments.length">
          <ul class="attachments-list">
            <li v-for="(attachment, index) in attachments" :key="index" class="attachment-item">
              <div class="attachment-info">
                <!-- File icon -->
                <i class="fa fa-file-download attachment-icon"></i>
                <!-- File name and type -->
                <span class="attachment-details">
                  <strong>{{ attachment.name }}</strong> <br />
                  <small>{{ attachment.type }}</small>
                </span>
              </div>
              <!-- Download button -->
              <a :href="getAttachmentUrl(attachment.id)" class="btn btn-primary btn-sm download-btn" download>
                Download
              </a>
            </li>
          </ul>
        </div>
        <div v-else>
          <p>No items attached.</p>
        </div>
      </div>
    </div>

    <floating-add-button icon="pen-to-square" @click="editTicket" v-if="ticket.status !== `CLOSED`"/>
  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import axios from 'axios'; // Ensure Axios is imported
import {utils as $utils} from "../../Services/utils";
import ticket from "@/views/Tickets/Ticket.vue";

export default {
  name: "TicketDetails",
  components: {LineSeparator, PageWithBackBtn, FullPage, FloatingAddButton},
  beforeMount() {
    this.getTicketDetails();
    this.getNotes();
    this.getAttachments();
  },
  data() {
    return {
      ticket: {
        notes: [], // Holds notes from API
        attachments: [], // Holds attachments from API
      },
      notes: [], // Separate field for notes
      attachments: [], // Separate field for attachments
      id: this.$route.params.id,
      isEditing: false,
      editableRow: [],
      userComment: '',
      showTicketDetails: false
    };
  },
  computed: {
    filteredNotes() {
      // Return only notes where internal is false
      return this.notes.filter(note => note.internal === false);
    },
    $utils() {
      return $utils
    }
  },
  methods: {
    getTicketDetails() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getTicketDetails`, this.id)
          .then((res) => this.ticket = res)
          .finally(() => this.$utils.hideLoading())
    },
    getNotes() {
      const notesUrl = ticketApi + `ticket/${this.id}`;
      axios.get(notesUrl)
          .then(response => {
            this.notes = response.data.notes || [];
          })
          .catch(error => {
            console.error("Error fetching notes:", error);
          });
    },
    getAttachments() {
      const attachmentsUrl = ticketApi + `ticket/${this.id}/attachment/getAttachments`;
      axios.get(attachmentsUrl)
          .then(response => {
            this.attachments = response.data || [];
          })
          .catch(error => {
            console.error("Error fetching attachments:", error);
          });
    },
    getAttachmentUrl(id) {
      return ticketApi +`ticket/${ticket.id}/attachment/${id}`;
    },
    stripHtmlTags(text) {
      const div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
    },
    editTicket() {
      return this.$router.push({
        name: 'Create-Ticket',
        query: {id: this.id}
      });
    }
  },
};
</script>
