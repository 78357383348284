import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'float',
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        searchFloat: async (context , payload) => {
            payload = { ...payload , ...context.getters.getGlobalDateRange };
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`float/${storeDB}/search`,{ params : payload })
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        updateFloat: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload = {
                ...payload,
                Authorisedby : user.Username
            }
            return axios.put(franchiseTomcatApi+`float/${storeDB}`,payload)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
    }
}
