import axios from "axios";
import {Storage} from "@/Services/Storage";
import queryStringify from "qs-stringify";

export default {
    name: 'Supplier',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllSuppliers: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi+`creditor/${storeDB}/search`,payload)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        updateSupplierCode: async (context, data) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi + `creditor/${storeDB}/updateID`, data)
                .catch((err) => errorHandler.tomcatError(err, true))
        },

        getSupplierBalance: async (context, supplierID) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`creditor/${storeDB}/getSupplierBalance?id=`+supplierID)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        getSupplierBalances: async (context, IDs) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = {IDs}
            return axios.post(backOfficeTomcatApi+`creditor/${storeDB}/getSupplierBalances`,obj)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        getSupplierByCode: async (context, supplierCode) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`creditor/${storeDB}/${supplierCode}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        createSupplier: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(franchiseTomcatApi + `supplier/${storeDB}`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getInvoicesBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/otherInvoices`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },


        changeSupplier: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi + `creditor/${storeDB}/updateID`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getCreditNotesBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/grvreturns`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        getGRVsBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/grvs`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        getPurchaseOrdersBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.get(backOfficeTomcatApi+ `purchaseOrder/${storeDB}/getBySupplier`,{
              params : payload,
            }).then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },
        getConsignmentInvoices:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.supplierID = id;
            return axios.get(franchiseTomcatApi + `GRV/${storeDB}/getConsignmentInvoicesBySupplier`,{
                params : payload,
            }).then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        getPaymentsBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/payments`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        creditSupplierPayment: async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            let obj = {user ,id};
            return axios.post(backOfficeTomcatApi+ `Payments/${storeDB}/returnSupplierPayment`,obj)
                .catch((err)=>errorHandler.tomcatError(err))
        },
        creditExpense: async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            let obj = {user ,id};
            return axios.post(backOfficeTomcatApi+ `Payments/${storeDB}/returnExpense`,obj)
                .catch((err)=>errorHandler.tomcatError(err))
        },

        getConsignmentDataBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/getConsignmentStats`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },
        getProductsSoldBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/getProductsSold`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },
        getProductsBoughtBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi+ `creditor/${storeDB}/getProductsBought`,payload)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        exportConsignmentDataBySupplier:async (context , id) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            payload = queryStringify(payload);
            return openUrl(franchiseApi+`supplier/ExportConsignmentStats/${storeDB}?`+payload);
        },

        makeSupplerPayment: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.ExclAmout = payload.InclAmount
            payload.User = user.Username;
            return axios.post(backOfficeTomcatApi + `Payments/${storeDB}/SupplierPayment`, payload)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        printSupplierStatement: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi + `supplier/ExportStatement/${storeDB}?id=`+id);
        },

        DeleteSupplierz: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `supplier/${storeDB}/${id}`);
        },

        printPDFSupplierStatement: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = context.getters.getGlobalDateRange;
            range = queryStringify(range);
            return openUrl(franchiseApi + `PDFReport/SupLedger/${id}/${storeDB}?`+range );
        },

        getTotalOwing: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+ `creditor/${storeDB}/getTotalOwing`)
                .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },
    }
}
