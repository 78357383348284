import axios from "axios";
import {Storage} from "@/Services/Storage";
import queryStringify from "qs-stringify";

export default {
    name: 'MainDebtor',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllMainDebtors:async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`mainDebtor/${storeDB}/getAll`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        createMainDebtor : async (context , payload) => {
            let user = await Storage.getCurrentUser();
            let storeDB = await Storage.getCurrentStoreDB();
            payload = {
                ...payload,
                createdby : user,
            }
            return axios.post(backOfficeTomcatApi+`mainDebtor/${storeDB}`,payload)
                .catch((err)=>errorHandler.tomcatError(err))
        },
        updateMainDebtor : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi+`mainDebtor/${storeDB}?id=`+payload.id,payload)
                .catch((err)=>errorHandler.tomcatError(err))
        },
        getMainDebtorByID : async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`mainDebtor/${storeDB}/${id}`)
                .then(({data}) => data).catch((err)=>errorHandler.tomcatError(err))
        },

        getDebtorsByMainDebtor : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
           return axios.get(backOfficeTomcatApi+`mainDebtor/${storeDB}/getStoresByMainDebtor?id=`+payload)
               .then(({data})=>data).catch((err)=>errorHandler.tomcatError(err))
        },

        addStoreToMainDebtor : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi+`mainDebtor/${storeDB}/addStoreToMainDebtor`,payload)
                .catch((err)=>errorHandler.tomcatError(err))
        },
        removeStoreFromMainDebtor : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(backOfficeTomcatApi+`mainDebtor/${storeDB}/removeStoreFromMainDebtor`, {
                params : payload
            }).catch((err)=>errorHandler.tomcatError(err))
        },

        printMainDebtorStatement : async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = context.getters.getGlobalDateRange;
            range.id = id;
            return openUrl(franchiseApi+`account/getMainDebtorStatement/${storeDB}?`+queryStringify(range));
        },
        exportMainDebtorInvoices : async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = context.getters.getGlobalDateRange;
            range.id = id;
            return openUrl(franchiseApi+`account/getMainDebtorInvoices/${storeDB}?`+queryStringify(range));
        },
        exportMainDebtorCredits : async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = context.getters.getGlobalDateRange;
            range.id = id;
            return openUrl(franchiseApi+`account/getMainDebtorCredits/${storeDB}?`+queryStringify(range));
        },
        exportMainDebtorPayments : async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let range = context.getters.getGlobalDateRange;
            range.id = id;
            return openUrl(franchiseApi+`AccPayments/getMainDebtorPayments/${storeDB}?`+queryStringify(range));
        },
    }
}
