<template>
  <div class="nav-bar mb-2">
    <button v-if="!hideButton" class="bg-transparent" @click="goBack">
      <font-awesome-icon class="icon" icon="fa-solid fa-arrow-left"></font-awesome-icon>
    </button>
    <h4 class="title">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  name: "BackNavigationBar",
  props: {
    title: {
      type: String,
      default: ''
    },hideButton:{
      type: Boolean,
      default: false
    },
    back: {
      type: Function,
    }
  }, methods: {
    goBack() {
      if (this.back) {
        this.back();
      } else {
        this.$router.back()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.nav-bar {
  display: inline-flex;
  gap: 20px;
  height: 50px;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  position: sticky;
  box-shadow: 1px -5px 12px rgb(0, 0, 0, 0.5);
  z-index: 1;

  .icon {
    font-size: 25px;
  }

  h4 {
    margin: 0;
    font-weight: 600;
    text-overflow: ellipsis;
  }

}
</style>
