<template>
  <div class="container-fluid navigation-bar p-1 px-2">
    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars" />
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box">
      <button v-if="isAndroid" @click="backToPOS" class="d-flex flex-row bg-transparent text-white fw-bolder">
        <font-awesome-icon icon="fingerprint" class="mx-2" />
        Back to POS
      </button>
    </div>

    <div class="user-settings my-2">
      <button @click="openSmartInvoice" v-if="(!isSmartInvoiced) && isSmartInvoiceLive" class="burger mx-2 red-blinking">
        <font-awesome-icon icon="triangle-exclamation" />
      </button>

      <Notification />

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
      </button>
    </div>

    <custom-modal title="Smart Invoice Sign up Pending" ref="smartInvoiceModal">
      <div v-if="hasSubmittedDocuments">
        <p>Documents submitted successfully.</p>
        <button @click="viewTicket" class="btn btn-primary">View Ticket Status</button>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-md-8 text-center">
          <p class="mb-3">You have not registered for smart invoicing. Please sign up now:</p>
          <a href="#" class="btn btn-primary tub" @click="goToSmartInvoiceScreen">
            Sign up now
          </a>
        </div>
      </div>

    </custom-modal>
  </div>
</template>
<script>
import Notification from "@/components/layout/navigation/Notification";
import { Storage } from "@/Services/Storage";
import CustomModal from "@/components/layout/utils/CustomModal";

export default {
  name: "NavigationBar",
  async created() {
    let isAndroid = await Storage.getAndroid();
    this.isAndroid = !!isAndroid && isAndroid !== "false";
  },
  data() {
    return {
      isAndroid: false,
    };
  },
  components: { CustomModal, Notification },
  props: {
    toggle: {
      type: Function,
      require: true,
    },
  },
  computed : {
    isSmartInvoiced(){
      return this.$store.getters.getSmartInvoicingStatus;
    },
    isSmartInvoiceLive(){
      return this.$store.getters.getSmartInvoicingLive;
    },
    hasSubmittedDocuments(){
      return this.$store.getters.getDocSubmissionStatus;
    },
  },
  methods: {
    syncData() {
      this.$utils.showLoading();
      return window.location.reload(true);
    },
    backToPOS() {
      return openUrl(`https://android.abc.co.zm/`, `_self`);
    },
    openSmartInvoice() {
      return this.$refs.smartInvoiceModal.openModal();
    },

    closeModal() {
      return this.$refs.smartInvoiceModal.closeModal();
    },
    viewTicket() {
      this.closeModal();
      let id = this.hasSubmittedDocuments;
      return this.$router.push({ name : `TicketDetail` , params : {id} })
    },
    goToSmartInvoiceScreen() {
      this.closeModal();
      return this.$router.push({name : `smart-invoicing-setup`});
    }
  }
};
</script>
<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

button.btn {
  margin: 5px;
}

.red-blinking {
  color: red;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
