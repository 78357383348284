import axios from "axios";
import {Storage} from "@/Services/Storage";
import Swal from "sweetalert2";

import router from "@/router";

export default {
    name: 'currencies',
    state: {
        baseCurrency: {
            id:1,
            Symbol: "K",
            Rate: 1,
            Base: true,
            Name: "Kwacha"
        },
        storeCurrencies : []
    },
    getters: {
        getBaseCurrency: (state) => state.baseCurrency,
        getStoreCurrencies: (state) => state.storeCurrencies,
        getCurrencyBySymbol: (state) => (symbol) => state.storeCurrencies.find(currency=>currency.Symbol === symbol),

    },
    mutations: {
        setBaseCurrency: async (state, payload) => {
            if (payload) await Storage.setBaseCurrency(payload)
            else payload = await Storage.getBaseCurrency();
            state.baseCurrency = payload ? payload : {
                Symbol: "K",
                Rate: 1,
                Base: true,
                Name: "Kwacha"
            };
        },
        setStoreCurrencies: async (state, payload) => {
            if (payload) await Storage.setStoreCurrencies(payload)
            else payload = await Storage.getStoreCurrencies();
            state.storeCurrencies = payload ? payload : [];
        },

    },
    actions: {
        getAllCurrencies: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`currency/${storeDB}/getAll`)
                .then(({data})=>context.commit(`setStoreCurrencies`, data))
                .catch(err=>errorHandler.tomcatError(err));
        },
        createCurrency: async (context , payload) =>  {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(backOfficeTomcatApi+`currency/${storeDB}`,payload)
                .then(()=> {
                    return Promise.all([
                        context.dispatch(`getBaseCurrency`),
                        context.dispatch(`getAllCurrencies`),
                    ])
                })
                .catch(err=>errorHandler.tomcatError(err));
        },
        updateCurrency: async (context , payload) =>  {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(backOfficeTomcatApi+`currency/${storeDB}/${payload.id}`,payload)
                .then(()=> {
                    return Promise.all([
                        context.dispatch(`getBaseCurrency`),
                        context.dispatch(`getAllCurrencies`),
                    ])
                })
                .catch(err=>errorHandler.tomcatError(err));
        },
        async getBaseCurrency(context) {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;

            return axios.get(franchiseTomcatApi + `currency/${storeDB}/getBaseCurrency`)
                .then(({ data }) => {
                    if (!data || (typeof data === 'object' && Object.keys(data).length === 0)) {
                        Swal.fire({
                            title: 'Base Currency Not Set',
                            text: 'Please set a base currency now to sell.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Add Base Currency',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                return router.push({name: 'currency'});
                            }
                        });
                    } else {
                        context.commit('setBaseCurrency', data);
                    }
                })
                .catch(err => errorHandler.tomcatError(err));
        },
    }
}
