<template>
  <div class="page">
    <button class="btn btn-primary" @click="logOutOfFranchise">
      <font-awesome-icon icon="fa-solid fa-lock" />
      Log Out
    </button>

    <div class="cover-header">
      <img alt="" class="img-fluid" src="../assets/logo.png" />
    </div>
    <h3 class="text-white text-center">Lyt-Soft BackOffice</h3>
    <form @submit.prevent="userLogin" class="login-box">
      <div class="col-12 text-center text-secondary">
        <h6>Chrilan Support Verification</h6>
      </div>

      <div class="form-group mb-2">
        <label class>
          <input
              class="form-control"
              placeholder="Enter Otp"
              v-model="otp"
              type="text"
               required
          />
        </label>
      </div>
      <button type="submit" class="login_btn">Login</button>
    </form>
  </div>
</template>

<script>
import { Storage } from "@/Services/Storage";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "SupportOtp",
  data() {
    return {
      otp: "",
    };
  },
  methods: {
    userLogin() {
      // Show loading spinner
      this.$utils.showLoading();

      return this.$store
          .dispatch('verifyOtp', { otp: this.otp })
          .then(() => {
            // Handle success if needed (e.g., navigation or user feedback)
          })
          .catch((err) => {
            // Handle error and set the warning message
            this.warning = err;
          })
          .finally(() => {
            // Ensure loading spinner is hidden
            this.$utils.hideLoading();
          });
    },

    logOutOfFranchise() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You are logging out of the Current Franchise",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#dd3",
        confirmButtonText: "Yes, Log Out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$utils.showLoading();
          let deviceID = await Storage.getDeviceID();
          localStorage.clear();
          await Storage.setDeviceID(deviceID);
          await this.$router.push({ name: "login" });
          return window.location.reload(true);
        }
      });
    },
  },
};
</script>

<style>
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}
</style>

<style lang="scss" scoped>
.page {
  height: 100vh;
}

.img-fluid {
  height: 250px;
  width: 260px;
  transition: 2s all ease-in-out;
  transform-origin: center;

  & :hover {
    transform: rotate(720deg);
  }
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
  /*background-image: url("../assets/images/bg.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);

  .form-group {
    position: relative;

    .icon {
      position: absolute;
      left: 10px;
      border-right: 1px solid rgba(222, 222, 222, 0.5);
      padding-right: 5px;
      top: 37px;
      color: rgba(200, 200, 200, 0.9);
      font-size: 1.6em;
    }
  }

  .form-control {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0);
    display: block;
    margin: 5px auto;
    text-align: left;
    padding: 0 40px 0 50px;
    height: 40px;
    border-radius: 1em;
    outline: none;
    color: #a0a0a0;
    transition: ease-in-out 200ms;
  }

  .form-control:focus {
    background-color: transparent;
    font-weight: 400;
    outline: none;
  }

  select {
    width: 100%;
  }

  .login_btn {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    color: white;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 300;
    background: linear-gradient(205deg, #0b2550 0%, #295687 100%);
    border-radius: 1em;
    outline: none;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  label {
    font-style: italic;
    font-weight: 500;
    font-size: 1em;
    color: #6c757d !important;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
</style>
