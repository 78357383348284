import axios from "axios";
import {Storage} from "../../../Services/Storage";
import queryStringify from "qs-stringify";

export default {
    name: 'stockTake',
    state: {
        stockTakeList: [],
        stockTakeItems: [],
    },
    getters: {
        getStockTakeList: (state) => state.stockTakeList,
        getStockTakeItems: (state) => state.stockTakeItems,
    },

    mutations: {
        setStockTakeList: (state, data) => state.stockTakeList = data,
        setStockTakeItems: async (state, data) => {
            state.stockTakeItems = data.map(item=>{
                item.countedQty = '';
                return item;
            })
        },
    },
    actions: {
        getStockTakeList: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `savedstocktakes/${storeDB}/getAll`)
                .then(({data}) => context.commit('setStockTakeList', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        getStockTakeItemsList: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `stocktakesavedlist/${storeDB}/getPluByID/${id}`)
                .then(({data}) => context.commit('setStockTakeItems', data))
                .catch((err) => errorHandler.tomcatError(err))

        },

        finalizeStockTake: async(context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let pc = 'Online';
            let items = context.getters.getStockTakeItems
                .filter(item=> item.countedQty !== '')
                .map(item => {
                    return {
                        'plu': item.PLU,
                        'counted': parseFloat(item.countedQty),
                    }
                });
            let data = {
                user: user.Username,
                PC : pc,
                items: items
            };
            return axios.post(serverApi + `stocktake/${storeDB}`,data)
                .then(({data}) => {
                    data = data.replaceAll("'",`"`);
                    data = JSON.parse(data);
                    openUrl(franchiseApi+`PDFReport/StockTake/${data.id}/${storeDB}`)
                    context.commit('setStockTakeItems', []);
                }).catch((err) => errorHandler.tomcatError(err))
        },
        getStockByDepartment : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`stock/${storeDB}/getByDepartment`,{params : payload})
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },
        exportStockTakeTemplate: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = queryStringify(payload)
            return openUrl(franchiseApi+`excel/exportStockTakeTemplate/${storeDB}?`+obj);
        },

        exportStockList: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            payload = queryStringify(payload)
            return openUrl(franchiseApi+`PDFReport/AllCategories/${storeDB}?`+payload);
        },

        printStockTakeTemplate: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = queryStringify(payload)
            return openUrl(franchiseApi+`PDFReport/printStockTakeTemplate/${storeDB}?`+obj);
        },
    }
}
