import axios from "axios"
import {Storage} from "@/Services/Storage";
import queryStringify from "qs-stringify";


export default {
    name: 'stockCategories',
    state: {
        categories: [],
    },
    getters: {
        getCategories: (state) => state.categories,
        getAllMainCategories: (state) => {
            let finalArray = [];
            let categories = state.categories.map(category => $utils.toPascalCase(category.MainCatName));
            for (let i = 0; i < categories.length; i++) {
                let mainCategory = categories[i];
                if (!finalArray.includes(mainCategory)) {
                    finalArray.push(mainCategory)
                }
            }
            return finalArray.sort((a, b) => a.localeCompare(b));
        },

        getAllSubCategories: (state) => (mainCategory) => {
            let finalArray = [];
            let categories = state.categories
                .filter(category => category.MainCatName.toLowerCase() === mainCategory.toLowerCase())
                .filter(category => {
                    if (category.SubCatName) return category.SubCatName.trim() !== '';
                }).map(category => $utils.toPascalCase(category.SubCatName));
            for (let i = 0; i < categories.length; i++) {
                let subCategory = categories[i];
                if (!finalArray.includes(subCategory)) {
                    finalArray.push(subCategory)
                }
            }
            return finalArray.sort((a, b) => a.localeCompare(b));
        },

        getAllSub2Categories: (state) => (mainCategory, subDep) => {
            let finalArray = [];
            if (!finalArray) return finalArray;
            let categories = state.categories
                .filter(category => category.MainCatName.toLowerCase() === mainCategory.toLowerCase())
                .filter(category => category.SubCatName && category.SubCatName.toLowerCase() === subDep.toLowerCase())
                .filter(category => category.SubCatName2.trim() !== '')
                .map(category => $utils.toPascalCase(category.SubCatName2));
            for (let i = 0; i < categories.length; i++) {
                let sub2Category = categories[i];
                if (!finalArray.includes(sub2Category)) {
                    finalArray.push(sub2Category)
                }
            }
            return finalArray.sort((a, b) => a.localeCompare(b));
        },

    },

    mutations: {
        setCategories: async (state, payload) => {
            if (!payload) payload = await Storage.getCategories();
            else await Storage.setCategories(payload);
            state.categories = payload
        },
    },
    actions: {
        searchZraCategories:async (context , payload) =>{
            return axios.get(franchiseTomcatApi+`category/maindb/searchZRACatgeories?name=`+payload)
                .then(({data})=>data)
        },
        createStockCategoryDialog: (context) => {
            return Swal.fire({
                title: 'Create New Stock Category',
                html: `
                 <input type="text" id="cat-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%" >
                 <label class="form-switch my-3">
                    <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory">
                   Franchise Category
                  </label>
                  
                 <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden">
                   Hide Category From Sales
                 </label>`,
                inputAutoTrim: true,
                showCancelButton: true,
                confirmButtonText: 'Create',
                showLoaderOnConfirm: true,

                preConfirm: () => {
                    let category = Swal.getPopup().querySelector('#cat-name').value;
                    let franchise = Swal.getPopup().querySelector('#franchiseCategory').checked;
                    let hidden = Swal.getPopup().querySelector('#isHidden').checked;
                    if (!category) return Swal.showValidationMessage(`Category name is required`)
                    let payload = {
                        MainCatName: category,
                        Franchise: franchise,
                        Stock: hidden
                    }
                    return context.dispatch('createMainCategory', payload)
                        .catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value === 'Department already exists !') {
                        return Swal.fire('FAILED', result.value, 'error')
                    }
                    return Swal.fire('Success', `Stock Category ${result.value} created successfully`, 'success')
                }
            })
        },

        editMainCategoryDialog(context, mainCategory) {
            let payload = context.getters.getCategories
                .filter(category => category.SubCatName.trim() === '')
                .find(category => category.MainCatName.toLowerCase() === mainCategory.toLowerCase())
            if (!payload) return responses.throwErr("An Error Occured \n Please Refresh!");
            let franchiseCategory = payload.Franchise;
            let isHidden = payload.Stock;
            return Swal.fire({
                title: 'Edit Stock Category',
                html: `
                    <input type="text" id="cat-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%" value="${mainCategory}"><label class="form-switch my-3">
                    <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory" ${franchiseCategory ? 'checked' : ''} ">
                   Franchise Category
                  </label>
                  <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden" ${isHidden ? `checked` : ``} >
                   Hide Category From Sales
                 </label>
`,
                showCancelButton: true,
                confirmButtonText: 'Apply',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    mainCategory = Swal.getPopup().querySelector('#cat-name').value;
                    if (!mainCategory) return Swal.showValidationMessage(`Category name is required`)
                    payload.MainCatName = mainCategory;
                    franchiseCategory = Swal.getPopup().querySelector('#franchiseCategory').checked;
                    payload.Franchise = franchiseCategory;

                    isHidden = Swal.getPopup().querySelector('#isHidden').checked;
                    payload.Stock = isHidden;
                    return context.dispatch('editMainCategory', payload).catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value === 'Department already exists !') {
                        return Swal.fire('FAILED', result.value, 'error')
                    }
                    return Swal.fire('Success', `Stock Category edited successfully`, 'success');
                }
            });
        },

        getCategories: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `category/${storeDB}/getAll`)
                .then(({data}) => {
                    data = data.filter(category => category.MainCatName.trim() !== '')
                        .sort((a, b) => a.MainCatName.localeCompare(b.MainCatName));
                    context.commit('setCategories', data)
                })

        },
        createMainCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                MainCatName: payload.MainCatName,
                Franchise: payload.Franchise,
                Stock: payload.Stock,
                zra_commodity: payload.zra_commodity,
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },

        editMainCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                MainCatName: payload.MainCatName,
                Franchise: payload.Franchise,
                Stock: payload.Stock
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },

        createUpdateMain: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `category/${storeDB}`, {
                id: payload.id,
                MainCatName: $utils.toPascalCase(payload.MainCatName),
                SubCatName: payload.SubCatName ? $utils.toPascalCase(payload.SubCatName) : null,
                SubCatName2: payload.SubCatName2 ? $utils.toPascalCase(payload.SubCatName2) : null,
                franchise: payload.Franchise ? payload.Franchise : false,
                Stock: payload.Stock ? payload.Stock : false,
                zra_commodity: payload.zra_commodity,
            }).then(() => $utils.toPascalCase(payload.MainCatName))
                .catch((err) => errorHandler.tomcatError(err))
                .finally(() => context.dispatch('getCategories'))
        },

        editSubCategoryDialog(context, payload) {
            let mainCategory = payload.MainCatName;
            let subCategory = payload.SubCatName;
            payload = context.getters.getCategories
                .filter(category => category.SubCatName2.trim() === '')
                .find(category => {
                    return category.MainCatName.toLowerCase() === mainCategory.toLowerCase() &&
                        category.SubCatName.toLowerCase() === subCategory.toLowerCase();
                });
            let franchiseCategory = payload.Franchise;
            let isHidden = payload.Stock;
            return Swal.fire({
                title: `Edit Sub Stock Category in ${mainCategory}`,
                html: `<input type="text" id="subcat-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%" value="${subCategory}">
                <label class="form-switch my-3">
                   <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory" ${franchiseCategory ? 'checked' : ''} ">
                   Franchise Category
                  </label>
                  
                  <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden" ${isHidden ? `checked` : ``} >
                   Hide Category From Sales
                 </label>

`,
                showCancelButton: true,
                confirmButtonText: 'Apply',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let name = Swal.getPopup().querySelector('#subcat-name').value;
                    if (!name) return Swal.showValidationMessage(`Category name is required`)
                    payload.SubCatName = name;
                    payload.Franchise = Swal.getPopup().querySelector('#franchiseCategory').checked
                    payload.Stock = Swal.getPopup().querySelector('#isHidden').checked
                    return context.dispatch('editSubCategory', payload)
                        .catch(error => Swal.showValidationMessage(`Request failed: ${error}`))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire('Success', `Stock Category edited successfully`, 'success');
                }
            });
        },

        editSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                MainCatName: payload.MainCatName,
                SubCatName: payload.SubCatName,
                Franchise: payload.Franchise,
                Stock: payload.Stock
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },


        createSubCategoryDialog(context, category) {
            return Swal.fire({
                title: `Create Sub Category in ${category}`,
                html: `
                    <input type="text" id="subcat-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%">
                    <label class="form-switch my-3">
                    <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory" >
                   Franchise Category
                  </label>
                  
                  <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden">
                   Hide Category From Sales
                 </label>`,
                showCancelButton: true,
                confirmButtonText: 'Create',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let sub = Swal.getPopup().querySelector('#subcat-name').value;
                    let franchise = Swal.getPopup().querySelector('#franchiseCategory').checked;
                    let isHidden = Swal.getPopup().querySelector('#isHidden').checked;
                    if (!sub) return Swal.showValidationMessage(`Category name is required`)
                    let obj = {
                        MainCatName: category,
                        SubCatName: sub,
                        Franchise: franchise,
                        Stock: isHidden,
                    }
                    return context.dispatch('createSubCategory', obj)
                        .catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value === 'Department already exists !') {
                        return Swal.fire('FAILED', result.value, 'error')
                    }
                    return Swal.fire('Success', `Sub Category ${result.value} created successfully`, 'success')
                }
            })
        },

        createSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: null,
                MainCatName: payload.MainCatName,
                SubCatName: payload.SubCatName,
                Franchise: payload.Franchise,
                Stock: payload.Stock
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },


        createSub2CategoryDialog(context, payload) {
            let mainCategory = payload.MainCatName;
            let subCategory = payload.SubCatName;
            return Swal.fire({
                title: `Create Sub 2 Category`,
                html: `<span>Main Category : ${mainCategory}</span> <br>
                        <span>Sub Category : ${subCategory}</span>
                        <input type="text" id="subcat-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%">
                    <label class="form-switch my-3">
                    <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory" >
                   Franchise Category
                  </label>
                  
                  <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden">
                   Hide Category From Sales
                 </label>
`,

                showCancelButton: true,
                confirmButtonText: 'Create',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let sub = Swal.getPopup().querySelector('#subcat-name').value;
                    if (!sub) return Swal.showValidationMessage(`Category name is required`)

                    payload.Franchise = Swal.getPopup().querySelector('#franchiseCategory').checked;
                    payload.Stock = Swal.getPopup().querySelector('#isHidden').checked;
                    payload.SubCatName2 = sub;
                    return context.dispatch('createUpdateMain', payload)
                        .catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value === 'Department already exists !') {
                        return Swal.fire('FAILED', result.value, 'error')
                    }
                    return Swal.fire('Success', `Sub 2 Category created successfully`, 'success')
                }
            })
        },

        editSub2CategoryDialog(context, payload) {
            let mainCategory = payload.MainCatName;
            let subCategory = payload.SubCatName;
            let sub2Category = payload.SubCatName2;
            payload = context.getters.getCategories
                .find(category => {
                    return category.MainCatName.toLowerCase() === mainCategory.toLowerCase() &&
                        category.SubCatName.toLowerCase() === subCategory.toLowerCase() &&
                        category.SubCatName2.toLowerCase() === sub2Category.toLowerCase();
                });
            let franchiseCategory = payload.Franchise;
            let isHidden = payload.Stock;
            return Swal.fire({
                title: `Edit Sub Category`,
                html: `
                  <span>Main Category : ${mainCategory}</span> <br>
                  <span>Sub Category : ${subCategory}</span>
                  <input type="text" id="subcat2-name" class="swal2-input" placeholder="Category Name" style="max-width: 70%" value="${sub2Category}">
                       <label class="form-switch my-3">
                   <input class="form-check-input" type="checkbox" role="switch" id="franchiseCategory" ${franchiseCategory ? 'checked' : ''} ">
                   Franchise Category
                  </label>
                  
                  <label class="form-switch my-3">
                  <input class="form-check-input" type="checkbox" role="switch" id="isHidden" ${isHidden ? `checked` : ''}>
                   Hide Category From Sales
                 </label>

`,
                showCancelButton: true,
                confirmButtonText: 'Apply',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let name = Swal.getPopup().querySelector('#subcat2-name').value;
                    if (!name) return Swal.showValidationMessage(`Category name is required`)
                    payload.SubCatName2 = name;
                    payload.Franchise = Swal.getPopup().querySelector('#franchiseCategory').checked;
                    payload.Stock = Swal.getPopup().querySelector('#isHidden').checked;
                    return context.dispatch('createUpdateMain', payload)
                        .catch(error => Swal.showValidationMessage(`Request failed: ${error}`))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire('Success', `Stock Category edited successfully`, 'success')
                }
            });
        },

        archiveCategory: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = `?${queryStringify(payload)}`;
            return axios.delete(franchiseTomcatApi + `category/${storeDB}/deleteCategory` + obj)
                .then(() => context.dispatch(`getCategories`))
                .catch(err => errorHandler.tomcatError(err, false, `Could not delete Category`))
        }

    }
}
