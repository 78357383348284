import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "./utils";
import {responses} from "./responses";
import {errorHandler} from "./errorHandler";
import queryStringify from "qs-stringify";
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import axios from "axios";
import copy from 'copy-to-clipboard';

window.copy = copy;
Vue.use(VueGoodTablePlugin);
window.franchiseApi = `https://chrilantech.com/LytSoftPosOnline/api/`
 // window.franchiseApi = `http://127.0.0.1:8000/api/`

window.regApi = `https://chrilantech.com/regmac/api/`
//  window.franchiseApi = `http://127.0.0.1:8000/api/`

window.serverApi = `https://overflow.abc.co.zm:8443/OnlineSalesServer/`
 // window.serverApi = `http://192.168.1.123:8181/OnlineSalesServer/`
window.serverapi2 = serverApi;

window.onlineSalesTomcatApi = serverApi

window.gateWayApi = `https://realtime.abc.co.zm:8443/KazangPaymentGateway/api/GateWay/`
// window.gateWayApi = `http://192.168.1.225:9333/KazangPaymentGateway/api/GateWay/`
window.stocklistApi2 = 'https://overflow.abc.co.zm:8443/FranchiseSpringServer/'

window.stocklistApi = 'https://lna.abc.co.zm/FranchiseSpringServer/'
// window.stocklistApi ='http://localhost:8181/FranchiseSpringServer/'

 window.franchiseTomcatApi = `https://overflow.abc.co.zm:8443/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://192.168.1.146:8181/Franchise_OnlineServer/`

window.subscriptionApi = `https://overflow.abc.co.zm:8443/SubscriptionManager/api/`
// window.subscriptionApi = `http://192.168.1.244:8181/SubscriptionManager/api/`

window.pettyCashApi = `https://lna.abc.co.zm/PettyCashApi/api/v1/`
// window.pettyCashApi = `http://192.168.1.222:8080/PettyCashApi/api/v1/`

window.StockTemplateApi = `https://chrilantech.com/LytSoftPosOnline/api/`


 window.backOfficeTomcatApi = `https://tom9.abc.co.zm/Lyt-SoftBackOfficeApi/`
 // window.backOfficeTomcatApi = `http://192.168.1.208:8443/Lyt-SoftBackOfficeApi/`

window.ticketApi = `https://lna.abc.co.zm/TicketApp/`
// window.ticketApi = `http://192.168.1.123:8181/TicketApp/`

window.Labelapi = backOfficeTomcatApi+`labels/`

window.ticketsnumberapi = ticketApi+`ticket/`

window.Safesapi = backOfficeTomcatApi;

window.synergyApi = `https://lna.abc.co.zm/SynergyApi/api/v1/`
// window.synergyApi = `http://localhost:8181/SynergyApi/api/v1/`

window.subscriptionTomcatApi = `https://overflow.abc.co.zm:8443/SubscriptionManager/api/`
// window.subscriptionTomcatApi = `http://localhost:8080/subscription/api/`
window.customerPanelApi = subscriptionTomcatApi + `adminCustomerPanel/`

window.printApi = `https://tom9.abc.co.zm/PrintApi/`
// window.printApi = `http://localhost:8080/PrintApi/`

// window.hardwareApi = `http://192.168.1.140:9090/`;
window.hardwareApi = `http://localhost:9090/`;

window.responses = responses;
window.errorHandler = errorHandler

const EventBus = new Vue()
window.EventBus = EventBus

window.log = (val) => console.log(val);
window.Swal = Swal;

window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}

Vue.prototype.$utils = utils;
window.$utils = utils;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
window.axios = axios;
window.stringify = (object) => queryStringify(object);
window.openUrl = (url, target = '_blank') => window.open(url, target, "_blank");
window.getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
window.pageOptions = {
    enabled: true,
    mode: 'pages',
    perPage: 10,
    position: 'bottom',
    perPageDropdown: [10, 100, 200],
    dropdownAllowAll: false,
    jumpFirstOrLast: true,
    pageLabel: 'page', // for 'pages' mode
}

window.isValidString = (string) => {
    if (!string) return false;
    string = string.toString().trim();
    return !(string === `null` || string === `undefined` || string === ``);

}

window.stripMimeType = (base64) => {
    const pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
    return base64.replace(pattern, '');
}



