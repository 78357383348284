<template>

  <div class="col-md-3 col-6">
    <a @click="recordExpense" class="content-card">
      <div class="container">
        <p>Record Expense</p>
      </div>
    </a>

    <button id="modal-btn" type="button" style="display: none" data-bs-toggle="modal"
            data-bs-target="#staticExpensePaymentBackdrop"></button>

    <div class="modal fade" id="staticExpensePaymentBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="staticExpensePaymentBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="staticExpensePaymentBackdropLabel">Capture Expense</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeExpenseModal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="makePayment">
              <div class="row mb-3">
                <div class="col-md-4">
                  <label for="safesList">Payment Method / Account</label>
                  <v-select id="safesList" v-model="paymentForm.PaidFrom" :required="!paymentForm.PaidFrom"
                            :options="safes"
                            @search="searchSafes">
                    <template slot="no-options">
                      <b>Type to search ...</b>
                    </template>
                  </v-select>
                </div>

                <div class="col-md-4">
                  <label for="expenseAccounts">Expense Account / Category</label>
                  <v-select id="expenseAccounts" v-model="paymentForm.Expense" :required="!paymentForm.Expense"
                            :options="expenseAccounts"
                            @search="searchExpenseAccounts">
                    <template slot="no-options">
                      <b>Type to search ...</b>
                    </template>
                  </v-select>
                </div>

                <div class="col-md-4">
                  <label for="expenseAccounts">Supplier</label>
                  <v-select id="expenseSupplier" v-model="paymentForm.SupID"
                            :reduce="item=>item.SuppCode"
                            label="SupplierName"
                            :options="suppliers"
                            @search="searchSuppliers">
                    <template slot="no-options">
                      <b>Type to search ...</b>
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4">
                  <div class="form-floating mt-2">
                    <input type="number" step="0.01" class="form-control" required
                           v-model="paymentForm.InclAmount" @input="inputIncl" id="paymentAmount" placeholder="Amount">
                    <label for="paymentAmount">Expense Amount</label>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-floating mt-2" v-if="paymentForm.hasVat">
                    <input type="text" class="form-control"
                           v-model="paymentForm.ExclAmout" @input="inputExcl" id="exclAmount" placeholder="exclAmount">
                    <label for="exclAmount">Exclusive Amount</label>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-switch mt-3 mx-2">
                    <input class="form-check-input m-1" role="switch" v-model="paymentForm.hasVat" type="checkbox"
                           name="flexRadioDefault" id="hasVAT">
                    <label class="form-check-label" for="hasVAT">
                      Expense has VAT
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <input type="text" class="form-control" required
                           v-model="paymentForm.invoiceNumber" id="invoiceNumber" placeholder="invoiceNumber">
                    <label for="invoiceNumber">Invoice No.</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mt-2">
                    <input type="datetime-local" class="form-control" required
                           v-model="paymentForm.date" id="paymentDate" placeholder="paymentDate">
                    <label for="paymentDate">Date</label>
                  </div>
                </div>
              </div>


              <div class="row mb-2"
                   v-if="$store.getters.getCurrentStore && $store.getters.getCurrentStore.StoreDB === `burntofi_chrilan`">
                <line-separator title="Link To Job Card" />
                <div >
                  <label for="jbSearch">Type Job Card details</label>
                  <input type="text" class="form-control mb-2" id="jbSearch" v-model="selectedJobCard"
                         @input="searchAllJobCards">
                </div>
                <div v-if="selectedJobCard">
                  <label for="jbSearchSelected" class="mb-1" v-if="searching">
                    Searching Job Cards
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </label>
                  <label for="jbSearchSelected"  class="mb-1" v-else>Select Job Card (Optional)</label>
                  <select v-if="!searching"  class="form-select" v-model="paymentForm.jobCard" id="jbSearchSelected">
                    <option v-for="option in jobCards" :value="option.id">
                      <div class="d-flex flex-column">
                        <span class="text-sm text-secondary">Job Card #{{ option.id }} </span>
                        Customer : {{ option.customerName }}
                        <span class="text-sm"> Title ({{ option.status }}): {{ option.title }}</span>
                        <span class="text-sm"> Assigned To : {{ option.assignedTo }}</span>
                        <line-separator/>
                      </div>
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-1">
                <label for="paymentDescription">Expense Reason / Description</label>
                <textarea class="form-control" placeholder="Expense Reason / Description" rows="3"
                          required id="paymentDescription" v-model="paymentForm.Reason"/>
              </div>

              <div class="row mt-2 d-flex justify-content-center">
                <button class="btn btn-primary w-50 h-100" type="submit">Process Payment</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineSeparator from "@/components/layout/utils/LineSeparator";

export default {
  name: 'CaptureExpense',
  components: {LineSeparator},
  beforeMount() {
    this.resetExpenseData();
  },
  data() {
    return {
      isAllowed: false,
      searching: false,
      safes: [],
      expenseAccounts: [],
      jobCards: [],
      suppliers: [],
      paymentForm: {
        jobCard: null
      },
      selectedJobCard: null
    }
  },
  methods: {
    makePayment() {
      this.$utils.showLoading();
      if (this.paymentForm.date.toString().includes('T')) {
        this.paymentForm.date = this.paymentForm.date.toString().replaceAll('T', ' ') + ':00';
      }
      if (!this.paymentForm.hasVat) {
        this.paymentForm.ExclAmout = Number(this.paymentForm.InclAmount);
        this.paymentForm.InclAmount = Number(this.paymentForm.InclAmount);
      }
      return this.$store.dispatch(`captureExpense`, this.paymentForm)
          .then(() => {
            responses.showSuccess(`Payment Successful`)
            document.getElementById("closeExpenseModal").click();
            this.resetExpenseData();
          }).finally(() => this.$utils.hideLoading())
    },

    resetExpenseData() {
      this.safes = [];
      this.expenseAccounts = [];
      this.jobCards = [];
      this.selectedJobCard = null;
      this.searching = false;
      this.paymentForm = {
        SupID: null,
        InclAmount: null,
        ExclAmout: null,
        invoiceNumber: null,
        PaidFrom: null,
        jobCard: null,
        date: this.$store.getters.currentTime,
        Store: false,
        hasVat: true,
        SupPayment: false,
        Expense: null,
        Reason: ''
      }
    },

    searchSuppliers(search, loading) {
      loading(true)
      let obj = {
        SupplierName: search,
        tpin: "",
        contact: "",
        Limit: 150,
      };
      return this.$store.dispatch(`getAllSuppliers`, obj)
          .then(res => this.suppliers = res)
          .finally(() => loading(false));
    },
    searchAllJobCards() {
      let search = this.selectedJobCard;
      this.searching = true;
      return this.$store.dispatch(`searchJobCards`, search)
          .then(res => this.jobCards = res)
          .finally(()=>this.searching = false);
    },

    inputIncl() {
      let taxData = this.$store.getters.getZraCategoryByLabel("A");
      let amount = Number(this.paymentForm.InclAmount);
      if (!this.paymentForm.hasVat || !taxData) this.paymentForm.ExclAmout = amount;
      let taxRate = Number(taxData.TaxRate);
      this.paymentForm.ExclAmout = this.$utils.roundAmount(amount / (1 + taxRate));
    },

    inputExcl() {
      let taxData = this.$store.getters.getZraCategoryByLabel("A");
      let amount = Number(this.paymentForm.ExclAmout);
      if (!this.paymentForm.hasVat || !taxData) this.paymentForm.InclAmount = amount;
      let taxRate = Number(taxData.TaxRate);
      this.paymentForm.InclAmount = this.$utils.roundAmount(amount * (1 + taxRate));
    },

    searchSafes(search, loading) {
      loading(true)
      let obj = {
        safeName: search,
        currency: null,
      }
      return this.$store.dispatch(`searchSafes`, obj)
          .then(res => this.safes = res)
          .finally(() => loading(false));
    },

    searchExpenseAccounts(search, loading) {
      loading(true)
      return this.$store.dispatch(`searchExpenseAccounts`, search)
          .then(res => this.expenseAccounts = res)
          .finally(() => loading(false));
    },
    async recordExpense() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'invoiceCapture');
      if (!isAllowed) return;
      document.getElementById(`modal-btn`).click();
    },
  }
}
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;
}

.content-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
  display: block;
}

.content-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.content-card .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.content-card p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}
.visually-hidden{
  font-size: 10px;
}
.text-sm {
  font-size: 12px;
}
</style>