import TimeManager from "../../Services/TimeManager";
import {errorHandler} from "@/Services/errorHandler";
export default {
    name: "tickets",
    state: {

    },
    getters: {

    },
    mutations: {
    },
    actions: {
        getTicketDetails:(context , id)=> {
            return axios.get(ticketApi+`ticket/getDetails?id=`+id)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getTicketById:(context , id)=> {
            return axios.get(ticketApi+`ticket/getByID?id=`+id)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
    }
}
