import TimeManager from "@/Services/TimeManager";
import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: "email",
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        saveEmailSetup: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi+`emailrecord/${storeDB}/setEmailSettings`, payload)
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Save Email settings`));
        },

        getEmail: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`emailrecord/${storeDB}/getEmailSettings`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },

        sendEmailDocument:async (context,payload)=>{
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username
            if (!payload.additionalNote) payload.additionalNote = `Please view the attachment below`
            payload.additionalNote = payload.additionalNote+`\n\n\n<b>Email Sent By ${user}</b>`
            +`<b>Email Generated By <a href="https://chrilantech.com">Lyt-Soft POS</a></b>`
            payload.additionalNote = payload.additionalNote.replaceAll(`\n`,`<br>`)
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange,
                user
            }
            return axios.post(franchiseTomcatApi+`emailrecord/${storeDB}/sendEmailDocument`,payload)
                .then(({data}) => data);
        }
    }
}
