<template>
  <div class="my-2">
    <p class="text-sm-center text-secondary">{{ title }}</p>
    <hr class="w-100 m-0">
  </div>
</template>

<script>
export default {
  name: "LineSeparator",
  props : {
    title : {
      default : '',
      type : String,
    }
  }
}
</script>

<style scoped>
hr{
  border: 1px solid black;
}

p{
  height: 7px;
}
</style>